import React from 'react';
import { useRouteError } from 'react-router-dom';
export class RouterError extends Error {
    constructor(message, statusCode) {
        super(message);
        this.status = statusCode;
        this.message = message;
    }
    toJSON() {
        return {
            message: this.message,
            status: this.status,
            name: this.name,
            stack: this.stack,
            config: this.config,
            code: this.code,
            request: this.request,
            response: this.response,
            isAxiosError: this.isAxiosError,
            toJSON: this.toJSON,
        };
    }
}
export default function ErrorPage(props) {
    const error = useRouteError() || { message: props.message };
    console.error(error);
    return (React.createElement("div", { id: "error-page" },
        React.createElement("h1", null, "Oops!"),
        React.createElement("p", null, "Sorry, an unexpected error has occurred."),
        React.createElement("p", null,
            React.createElement("i", null,
                error.statusText,
                " ",
                error.message))));
}
