import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, Slide, Stack, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import { useNavigate } from 'react-router-dom';
import { tokens } from '../../styles/theme';
export function useDelayedArray(items, delay) {
    const [displayedItems, setDisplayedItems] = useState([]);
    const timeoutId = useRef(null);
    useEffect(() => {
        // Function to add items with delay
        const addWithDelay = async (index) => {
            if (index >= items.length)
                return;
            setDisplayedItems((prevItems) => [...prevItems, items[index]]);
            timeoutId.current = setTimeout(() => addWithDelay(index + 1), delay);
        };
        // Start the loop
        addWithDelay(0);
        // Clear the timeout if the component unmounts
        return () => {
            setDisplayedItems([]);
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
            }
        };
    }, [items, delay]); // Only re-run the effect if the delay changes
    return displayedItems;
}
export const GameMiniature = ({ children, id, game, ...props }) => {
    const { style, ...rest } = props;
    const [enabled, setEnabled] = useState(false);
    return (React.createElement(Stack, { direction: 'row', height: '14vw', gap: '1vw' },
        React.createElement(Stack, { width: '80%', gap: "1vw" },
            React.createElement("img", { height: "80%", alt: game.name, src: game.image }),
            React.createElement(Typography, { fontSize: "1vw", lineHeight: "1vw", align: 'left' }, game.code)),
        React.createElement(Stack, { direction: 'column', height: '80%', textAlign: 'left', gap: '3vw' },
            React.createElement(Typography, { fontSize: "1.39vw", lineHeight: "1.5vw", minHeight: "3vw" }, game.name),
            React.createElement(Typography, { fontSize: "0.75vw", lineHeight: "1vw", width: '11vw', height: '7vw', className: 'text-overflow' }, game.description))));
};
const GameList = ({ items }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const delay = 50;
    const displayedItems = useDelayedArray(items, delay);
    const navigate = useNavigate();
    return (React.createElement(Box, { m: 'auto', maxWidth: '90vw' },
        React.createElement(Tabs, { value: 'game' },
            React.createElement(Tab, { label: "Game", value: "game" }),
            React.createElement(Tab, { disabled: true, label: "Player 360", value: "player" })),
        React.createElement(Stack, { justifyContent: 'top', marginTop: '2vw', maxHeight: '70vh', overflow: 'auto' },
            React.createElement(TransitionGroup, null,
                React.createElement(Grid, { width: "-webkit-fill-available", container: true, spacing: 2, margin: '3vw 3vw 3vw 3vw' }, displayedItems.map((item, index) => (React.createElement(Slide, { key: item.id, direction: "up", in: true, timeout: 10 * delay, mountOnEnter: true, unmountOnExit: true, style: { transformOrigin: 'top left' } },
                    React.createElement(Stack, { style: {
                            transitionDelay: `${index * 10 * delay}ms`,
                        }, sx: {
                            borderRadius: 2,
                            height: '15vw',
                            width: '25vw',
                            margin: 1,
                            overflow: 'hidden',
                            transition: 'opacity 0.5s ease-out, transform 0.5s ease-out',
                            opacity: 0.9,
                            transform: 'rotateY(-90deg)',
                            '&:hover': {
                                opacity: 1,
                                transform: 'none',
                            },
                        } },
                        React.createElement(Button, { onClick: () => {
                                navigate(`/game/${item.id}/players`);
                            }, disabled: item.id !== 0, sx: { height: '100%' } },
                            React.createElement(GameMiniature, { game: item })))))))))));
};
export default GameList;
