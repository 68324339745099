import React from 'react';
import { Sidebar, Menu, MenuItem, SubMenu, menuClasses } from 'react-pro-sidebar';
import { Button, Stack, Typography } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import { SelectGameComponent } from './SelectGame';
import { useTheme } from '@mui/material/styles';
import { tokens } from '../../styles/theme';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const themes = {
    light: {
        sidebar: {
            backgroundColor: '#ffffff',
            color: '#607489',
        },
        menu: {
            menuContent: '#fbfcfd',
            icon: '#0098e5',
            hover: {
                backgroundColor: '#c5e4ff',
                color: '#44596e',
            },
            disabled: {
                color: '#9fb6cf',
            },
        },
    },
    dark: {
        sidebar: {
            backgroundColor: '#0b2948',
            color: '#8ba1b7',
        },
        menu: {
            menuContent: '#082440',
            icon: '#59d0ff',
            hover: {
                backgroundColor: '#00458b',
                color: '#b6c8d9',
            },
            disabled: {
                color: '#3e5e7e',
            },
        },
    },
};
// hex to rgba converter
const hexToRgba = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
function printDashboardItem(item, index) {
    const { pathname: currentPath } = useLocation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const location = useLocation();
    return (React.createElement("div", { key: index, style: {
            background: location.pathname.includes(item.link) ? colors.cyanAccent[700] : undefined,
            color: location.pathname.includes(item.link) ? themes['dark'].menu.hover.color : undefined
        } }, item.component ?
        item.component :
        item.items?.length > 0 ?
            React.createElement(SubMenu, { disabled: item.disabled, label: item.label, icon: item.icon, component: React.createElement(NavLink, { to: item.link }) }, item.items.map(printDashboardItem)) :
            React.createElement(MenuItem, { disabled: item.disabled, icon: item.icon, component: React.createElement(NavLink, { to: item.link }) }, item.label)));
}
export function MyProSidebar({ children, items, title, selectGame, selectedGame }) {
    const [collapsed, setCollapsed] = React.useState(false);
    const [toggled, setToggled] = React.useState(true);
    const [broken, setBroken] = React.useState(false);
    const rtl = false;
    const hasImage = false;
    const theme = 'dark';
    const muiTheme = useTheme();
    const menuItemStyles = {
        root: {
            fontSize: '13px',
            fontWeight: 400,
        },
        icon: {
            color: themes[theme].menu.icon,
            [`&.${menuClasses.disabled}`]: {
                color: themes[theme].menu.disabled.color,
            },
        },
        SubMenuExpandIcon: {
            color: '#b6b7b9',
        },
        subMenuContent: ({ level }) => ({
            backgroundColor: level === 0
                ? hexToRgba(themes[theme].menu.menuContent, hasImage && !collapsed ? 0.4 : 1)
                : 'transparent',
        }),
        button: {
            [`&.${menuClasses.disabled}`]: {
                color: themes[theme].menu.disabled.color,
            },
            '&:hover': {
                backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, hasImage ? 0.8 : 1),
                color: themes[theme].menu.hover.color,
            },
        },
        label: ({ open }) => ({
            fontWeight: open ? 600 : undefined,
        }),
    };
    return (React.createElement("div", { style: { display: 'flex', flex: 1, direction: rtl ? 'rtl' : 'ltr' } },
        broken &&
            React.createElement("div", { style: {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    margin: '16px',
                    zIndex: 1000,
                } },
                React.createElement(Button, { className: "sb-button", onClick: () => setToggled(!toggled) }, "Toggle")),
        React.createElement(Sidebar, { collapsed: collapsed, toggled: toggled, onBackdropClick: () => setToggled(false), onBreakPoint: setBroken, image: "https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg", rtl: rtl, breakPoint: "md", backgroundColor: hexToRgba(themes[theme].sidebar.backgroundColor, hasImage ? 0.9 : 1), rootStyles: {
                color: themes[theme].sidebar.color,
            }, style: { borderRight: `solid 4px ${muiTheme.palette.secondary.main}39` } },
            React.createElement("div", { style: { display: 'flex', flexDirection: 'column', height: '100%' } },
                React.createElement("div", { style: { color: '#44596e' } },
                    React.createElement(Stack, { style: { padding: '10px 8px 4px 8px' }, direction: "row-reverse" },
                        React.createElement(Button, { id: "collapse", style: {
                                transform: collapsed ? undefined : 'rotate(-180deg)',
                                background: "transparent",
                                border: 'none',
                                minWidth: "0px",
                                width: "2.5vw",
                                height: "5vh",
                                borderRadius: "50%"
                            }, onClick: () => setCollapsed(!collapsed), "aria-label": "Collapse" },
                            React.createElement(ArrowForwardIosIcon, null)))),
                React.createElement("div", { style: { flex: 1, marginBottom: '32px' } },
                    title &&
                        React.createElement("div", { style: { padding: '0 24px', marginBottom: '8px' } },
                            React.createElement(Typography, { variant: "body2", fontWeight: 600, style: { opacity: collapsed ? 0 : 0.7, letterSpacing: '0.5px' } }, title)),
                    selectGame &&
                        React.createElement(SelectGameComponent, { reduced: collapsed, game: selectedGame }),
                    React.createElement(Menu, { menuItemStyles: menuItemStyles }, items?.map(printDashboardItem))))),
        React.createElement("div", { style: { display: 'flex', flex: 1, flexDirection: 'column' } }, children)));
}
