import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { useNavigate } from 'react-router-dom';
export function SelectGameComponent({ children, id, game, reduced, ...props }) {
    const { style, ...rest } = props;
    const navigate = useNavigate();
    return (React.createElement(Button, { style: { margin: '0.5rem' }, ...rest, onClick: () => navigate('/game') },
        React.createElement(Stack, { direction: 'column', height: '80%', textAlign: 'left', gap: '0.5rem' }, game ?
            React.createElement(React.Fragment, null,
                React.createElement("img", { width: '100%', height: '100%', alt: game.name, src: game.image }),
                React.createElement(Stack, { direction: !reduced ? 'row' : 'column', flex: 1, alignItems: 'center' },
                    !reduced &&
                        React.createElement(Stack, { flex: 1 },
                            React.createElement(Typography, { fontSize: "0.9rem", lineHeight: "1.3rem", className: 'text-overflow' }, game.name)),
                    React.createElement(SwapHorizIcon, { fontSize: 'large' })))
            :
                React.createElement(React.Fragment, null, "Back to Select Game"))));
}
