export const banFeatures = [
    {
        id: 'CHAT',
        category: 'Social',
        type: 'Chat Feature',
        first: 'Suspension for 72 hours',
        second: 'Suspension for 7 days with warning before closure',
        third: 'Permanent suspension',
    },
    {
        id: 'UGC_A',
        category: 'Content',
        type: 'User Generated Content Asset',
        first: 'Suspension for 7 days with warning before closure',
        second: 'Permanent suspension',
        third: 'N/A',
    },
    {
        id: 'UGC_N',
        category: 'Content',
        type: 'User Generated Content Naming',
        first: 'Suspension for 72 hours',
        second: 'Suspension for 7 days with warning before closure',
        third: 'Permanent suspension',
    },
    {
        id: 'M_COOP',
        category: 'Multiplayer',
        type: 'Multiplayer Cooperative',
        first: 'Suspension for 24 hours',
        second: 'Suspension for 72 hours',
        third: 'Suspension for 7 days',
    },
    {
        id: 'M_VERSUS',
        category: 'Multiplayer',
        type: 'Multiplayer Versus',
        first: 'Suspension for 3 hours',
        second: 'Suspension for 24 hours',
        third: 'Suspension for 72 hours',
    },
];
export function getFeatureFromCode(code) {
    return banFeatures.find((feature) => feature.id === code);
}
