export const banReasons = [
    {
        id: 'ABUS',
        category: 'Cheating',
        type: 'Abusing game mechanics (bugs, glitches, exploits)',
        first: 'Suspension for 72 hours',
        second: 'Suspension for 7 days with warning before closure',
        third: 'Permanent suspension',
        icon: 'GamepadIcon',
    },
    {
        id: 'THIRD',
        category: 'Cheating',
        type: 'Using third-party software',
        first: 'Suspension for 7 days with warning before closure',
        second: 'Permanent suspension',
        third: 'N/A',
        icon: 'TerminalIcon',
    },
    {
        id: 'MOD',
        category: 'Cheating',
        type: 'Modifying game files',
        first: 'Suspension for 72 hours',
        second: 'Suspension for 7 days with warning before closure',
        third: 'Permanent suspension',
        icon: 'DriveFileRenameOutlineIcon',
    },
    {
        id: 'UNAUTH',
        category: 'Cheating',
        type: 'Unauthorized account access',
        first: 'Suspension for 24 hours',
        second: 'Suspension for 72 hours',
        third: 'Suspension for 7 days',
        icon: 'GroupIcon',
    },
    {
        id: 'HARASS',
        category: 'Behavior & Communication',
        type: 'Harassment, threats',
        first: 'Suspension for 3 hours',
        second: 'Suspension for 24 hours',
        third: 'Suspension for 72 hours',
        icon: 'CampaignIcon',
    },
    {
        id: 'INSULT',
        category: 'Behavior & Communication',
        type: 'Insults, vulgarity',
        first: 'Suspension for 3 hours',
        second: 'Suspension for 24 hours',
        third: 'Suspension for 72 hours',
        icon: 'FeedbackIcon',
    },
    {
        id: 'TOXIC',
        category: 'Behavior & Communication',
        type: 'Toxicity',
        first: 'Warning',
        second: 'Suspension for 3 hours',
        third: 'Suspension for 24 hours',
        icon: 'ThumbDownAltIcon',
    },
    {
        id: 'INAPP',
        category: 'Behavior & Communication',
        type: 'Highly inappropriate statements (hatred, discrimination, etc.)',
        first: 'Suspension for 24 hours',
        second: 'Suspension for 72 hours',
        third: 'Suspension for 7 days',
        icon: 'SpeakerNotesOffIcon',
    },
    {
        id: 'INAPPN',
        category: 'Names',
        type: 'Inappropriate, vulgar',
        first: 'Warning',
        second: 'Suspension for 3 hours',
        third: 'Suspension for 24 hours',
        icon: 'FormatStrikethroughIcon',
    },
    {
        id: 'IMPERSON',
        category: 'Names',
        type: 'Impersonation',
        first: 'Warning',
        second: 'Suspension for 3 hours',
        third: 'Suspension for 24 hours',
        icon: 'FileCopyIcon',
    },
    {
        id: 'HIGHOFF',
        category: 'Names',
        type: 'Highly offensive (hatred, discrimination, etc.)',
        first: 'Suspension for 24 hours',
        second: 'Suspension for 72 hours',
        third: 'Suspension for 7 days',
        icon: 'SpatialAudioIcon',
    },
];
export function getReasonFromCode(code) {
    return banReasons.find((reason) => reason.id === code);
}
