import axios from 'axios';
import { authProvider } from '../authentication/auth';
export async function sendRequest(path = '', method = 'GET', params) {
    const networkResponse = {
        unauthorized: false,
    };
    if (!(await authProvider.isAuthenticated())) {
        await authProvider.signout();
        networkResponse.unauthorized = true;
    }
    else {
        try {
            const url = new URL(`v1/${path}`, import.meta.env.VITE_ADMIN_API_URL)
                .href;
            switch (method) {
                case 'GET':
                    networkResponse.result = (await axios.get(url)).data;
                    break;
                case 'POST':
                    networkResponse.result = (await axios.post(url, params)).data;
                    break;
                case 'PUT':
                    networkResponse.result = (await axios.put(url, params)).data;
                    break;
                case 'DELETE':
                    networkResponse.result = (await axios.delete(url)).data;
                    break;
                default:
                    throw new Error(`Invalid HTTP method: ${method}`);
            }
        }
        catch (error) {
            const axiosError = error;
            if (axiosError) {
                if (axiosError.response?.status === 401) {
                    await authProvider.signout();
                    networkResponse.unauthorized = true;
                }
                else {
                    throw new Error(`Server Error : ${axiosError.response
                        ? axiosError.response.data
                        : axiosError.message}`);
                }
            }
            else {
                throw new Error(`Unknown error : ${error}`);
            }
        }
    }
    return networkResponse;
}
