import React from 'react';
import { Convert } from '../utils/tools';
import { Button, Menu, MenuItem } from '@mui/material';
function AuthMiniature(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const isMenu = props.menuActions && props.menuActions.length > 0;
    const size = props.size || 3;
    const fontSize = size / 2 - 0.11;
    return (React.createElement("div", { style: {
            width: `${size}rem`,
            height: `${size}rem`,
        } },
        React.createElement(Button, { style: {
                borderRadius: '50%',
                width: `${size}rem`,
                fontSize: `${fontSize}rem`,
                height: `${size}rem`,
                minWidth: 0,
                background: 'transparent',
                backgroundColor: Convert.stringToColour(props.playername),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textTransform: 'uppercase',
                pointerEvents: isMenu ? undefined : 'none',
            }, onClick: handleClick }, props.playername.slice(0, 2)),
        isMenu &&
            React.createElement(Menu, { id: "basic-menu", anchorEl: anchorEl, anchorOrigin: { vertical: 'bottom', horizontal: 'left' }, open: open, onClose: handleClose, MenuListProps: {
                    'aria-labelledby': 'basic-button',
                } }, props.menuActions?.map(action => {
                return (React.createElement(MenuItem, { onClick: action.method }, action.label));
            }))));
}
export default AuthMiniature;
