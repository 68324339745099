import React from 'react';
import { Outlet, useLoaderData, useNavigate, } from 'react-router-dom';
import { getPlayer } from '../../models/players';
import { authProvider } from '../../authentication/auth';
import { RouterError } from '../error-page';
import { Button } from '@mui/material';
export async function action({ request, params }) {
    const formData = await request.formData();
    console.log(`update ${formData} for player ${params.playerId}`);
}
export async function loader({ params, request }) {
    const getPlayerResponse = await getPlayer(params.playerId);
    if (getPlayerResponse.unauthorized) {
        return authProvider.getRedirectResponse(request);
    }
    if (!getPlayerResponse.result) {
        throw new RouterError('Not Found', 404);
    }
    return { player: getPlayerResponse.result, gameId: params.gameId };
}
export default function Player() {
    const navigate = useNavigate();
    const { player, gameId } = useLoaderData();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { display: 'flex', flex: 1, flexDirection: 'column' } },
            React.createElement("div", { id: "player" },
                React.createElement(Button, { type: "button", onClick: () => {
                        navigate(`/game/${gameId}/players`);
                    }, id: "hide-player-button" }, "Close"),
                React.createElement("div", null,
                    React.createElement("h1", null, player.platform_user_id),
                    player.metadata && (React.createElement("div", { style: {
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '0.25rem',
                        } }, Object.keys(player.metadata).map((key, index) => {
                        return (React.createElement("p", { key: `metadata-${index}`, style: { margin: '0px' } },
                            key,
                            " | ",
                            player.metadata[key]));
                    })))),
                React.createElement("div", null,
                    React.createElement("img", { style: { width: '4rem', height: '4rem' }, key: player.player_id, src: `https://robohash.org/${player.id}.png?size=200x200` }))),
            React.createElement(Outlet, null))));
}
