import React from 'react';
import { Outlet } from 'react-router-dom';
import { AuthStatus } from '../authentication/auth-status';
export default function Root() {
    return (React.createElement("div", { style: { width: '100%', display: 'flex', flexDirection: 'column' } },
        false && React.createElement(AuthStatus, null),
        React.createElement("div", { style: {
                display: 'flex',
                flex: 1,
            } },
            React.createElement(Outlet, null))));
}
