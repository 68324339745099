import { redirect } from 'react-router-dom';
import { deleteBan } from '../../models/bans';
import { authProvider } from '../../authentication/auth';
export async function action({ params, request }) {
    const deleteBanResponse = await deleteBan(params.banId);
    if (deleteBanResponse.unauthorized) {
        return authProvider.getRedirectResponse(request);
    }
    return redirect(`/players/${params.playerId}/bans`);
}
