import React from 'react';
import { useFetcher, useRouteLoaderData } from 'react-router-dom';
// import { fakeInitPlayers } from '../models/fakenetwork';
import { Dialog, Stack } from '@mui/material';
import AuthMiniature from './auth-miniature';
export function AuthStatus() {
    // Get our logged in user, if they exist, from the root route loader data
    const { user, isAuthenticated } = useRouteLoaderData('root');
    const [dialogContent, setDialogContent] = React.useState(false);
    const fetcher = useFetcher();
    const handleSubmit = () => {
        if (!confirm('Are you sure you want to log out of your current session ?')) {
            return;
        }
        fetcher.submit(null, { method: "post", action: "/logout" });
    };
    const showDetails = () => {
        setDialogContent(React.createElement("div", null,
            React.createElement(Stack, { p: "1rem", justifyContent: "center", alignItems: "center", minWidth: "20rem", minHeight: "30rem", gap: "3rem" },
                React.createElement(AuthMiniature, { playername: user || "..", size: 8 }),
                React.createElement(Stack, { direction: "row", gap: '1rem' },
                    React.createElement("p", null, "Your email : "),
                    React.createElement("p", null, user)))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "auth-status" }, !isAuthenticated ? (React.createElement("p", null, "You are not logged in.")) : (React.createElement(AuthMiniature, { playername: user || "..", menuActions: [
                { label: "Sign out", method: handleSubmit },
                { label: "My account", method: showDetails }
            ] }))),
        React.createElement(Dialog, { open: !!dialogContent, onClose: () => { setDialogContent(false); } }, dialogContent)));
}
