import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import localforage from 'localforage';
import { redirect } from 'react-router-dom';
export const authProvider = {
    isAuthenticated: async function () {
        const session = (await localforage.getItem('sessionInfo'));
        this.username = session?.username;
        return this._isValidToken(session?.sessionToken);
    },
    username: undefined,
    _loginRedirect: false,
    async signin(username, idToken) {
        //await new Promise((r) => setTimeout(r, 500)); // fake delay
        const sessionToken = await this._getSessionToken(idToken);
        const session = {
            sessionToken: sessionToken,
            username: username,
        };
        await localforage.setItem('sessionInfo', session);
        this.username = username;
        this._loginRedirect = false;
    },
    async signout() {
        await localforage.setItem('sessionInfo', null);
        this.username = '';
    },
    _isValidToken(token) {
        // return true;
        if (token) {
            try {
                const decoded = jwtDecode(token);
                const now = Date.now();
                if (decoded.exp) {
                    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
                    return decoded.exp * 1000 > now;
                }
            }
            catch (e) {
                console.error(e);
            }
        }
        axios.defaults.headers.common = {};
        return false;
    },
    async _getSessionToken(idToken) {
        try {
            const response = await axios.get(new URL('v1/login/azuread', import.meta.env.VITE_ADMIN_API_URL).href, {
                params: { access_token: idToken },
            });
            const reponseData = response.data;
            return reponseData.session_token;
        }
        catch (error) {
            console.error(JSON.stringify(error));
            throw error;
        }
    },
    getRedirectResponse(request, url = '/login') {
        const params = new URLSearchParams();
        params.set('from', new URL(request.url).pathname);
        if (!this._loginRedirect && !window.location.pathname.includes('login')) {
            alert('You are not authenticated. You will be redirected to the login screen.');
            this._loginRedirect = true;
        }
        return redirect(`${url}?` + params.toString());
    },
    getRedirectUrl(request, url = '/login') {
        const params = new URLSearchParams();
        params.set('from', new URL(request.url).pathname);
        if (!this._loginRedirect && !window.location.pathname.includes('login')) {
            alert('You are not authenticated. You will be redirected to the login screen.');
            this._loginRedirect = true;
        }
        return `${url}?` + params.toString();
    },
};
