import { banDtoToModel } from './bans';
// import { sendRequest } from './network';
import { Convert } from '../utils/tools';
// import { fakeGetPlayer, fakeGetPlayers } from './fakenetwork';
import { sendRequest } from './network';
function playerDtoToModel(playerDto) {
    const model = {
        id: `${playerDto.player_id}`,
        player_id: playerDto.player_id,
        created_at: Convert.dateStringToNumber(playerDto.created_at),
        updated_at: Convert.dateStringToNumber(playerDto.updated_at),
        deleted_at: Convert.dateStringToNumber(playerDto.deleted_at),
        platform_user_id: playerDto.platform_user_id,
        platform_code: playerDto.platform_code,
        game_code: playerDto.game_code,
        last_login: Convert.dateStringToNumber(playerDto.last_login),
        bans: playerDto.bans?.map(banDtoToModel) ?? [],
        metadata: playerDto.metadata,
    };
    return model;
}
let loadedPlayer = undefined;
export async function getPlayer(id, fetch = true) {
    if (fetch || loadedPlayer === undefined || loadedPlayer.id !== id) {
        // const response = await fakeGetPlayer(id);
        const response = await sendRequest(`player/${id}`);
        if (response.result) {
            loadedPlayer = playerDtoToModel(response.result);
        }
        return {
            result: loadedPlayer,
            unauthorized: response.unauthorized,
        };
    }
    return {
        result: loadedPlayer,
        unauthorized: false,
    };
}
export async function getPlayers(query) {
    console.log(query);
    // const response = await fakeGetPlayers();
    const response = await sendRequest(`players?${query}`);
    if (response.result && response.result.players) {
        let players = response.result.players.map(playerDtoToModel);
        if (!players)
            players = [];
        return {
            result: { players, count: response.result.count },
            unauthorized: response.unauthorized,
        };
    }
    return {
        result: response.result ? { players: [], count: 0 } : undefined,
        unauthorized: response.unauthorized,
    };
}
export async function updatePlayer(id, updates) {
    console.warn(`updatePlayer ${id} with ${updates} : not implemented`);
    return {
        result: false,
        unauthorized: false,
    };
}
export async function deletePlayer(id) {
    console.warn(`deletePlayer ${id} : not implemented`);
    return {
        result: false,
        unauthorized: false,
    };
}
export async function createPlayer(player) {
    console.warn(`createPlayer ${player} : not implemented`);
    return {
        unauthorized: false,
    };
}
