// import { HumanizeDurationLanguage, HumanizeDuration } from 'humanize-duration-ts';
import moment from 'moment';
export class Convert {
    // private static instance: Convert;
    // private langService: HumanizeDurationLanguage;
    // private humanizer: HumanizeDuration;
    // private constructor() {
    //   this.langService = new HumanizeDurationLanguage();
    //   this.humanizer = new HumanizeDuration(this.langService);
    // }
    // static getInstance(): Convert {
    //   if (!Convert.instance) {
    //     Convert.instance = new Convert();
    //   }
    //   return Convert.instance;
    // }
    static dateStringToNumber(dateString) {
        return moment(dateString).valueOf();
    }
    static durationToNumber(duration) {
        const isoDuration = moment.duration(duration);
        return isoDuration.asMilliseconds();
    }
    static numberToDuration(number) {
        const isoDuration = moment.duration(number, 'millisecond');
        return isoDuration.toISOString();
    }
    static numberToHumanDuration(number) {
        //    return this.getInstance().humanizer.humanize(number || 0);
        const isoDuration = moment.duration(number, 'millisecond');
        return number && number > 0 ? isoDuration.humanize() : "no duration";
    }
    static numberToHumanDate(dateNumber, format = 'DD/MM/YYYY') {
        const isoDate = moment(dateNumber);
        return isoDate.format(format);
    }
    static hoursToMilliseconds(hours) {
        return hours * 60 * 1000;
    }
}
Convert.stringToColour = (str) => {
    let hash = 0;
    str.split('').forEach(char => {
        hash = char.charCodeAt(0) + ((hash << 5) - hash);
    });
    let colour = '#';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff;
        colour += value.toString(16).padStart(2, '0');
    }
    return colour;
};
