import React from 'react';
import { Convert } from '../../utils/tools';
import { Badge, Button, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LockIcon from '@mui/icons-material/Lock';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GavelIcon from '@mui/icons-material/Gavel';
import FeedbackIcon from '@mui/icons-material/Feedback';
import TerminalIcon from '@mui/icons-material/Terminal';
import GamepadIcon from '@mui/icons-material/Gamepad';
import GroupIcon from '@mui/icons-material/Group';
import CampaignIcon from '@mui/icons-material/Campaign';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import SpatialAudioIcon from '@mui/icons-material/SpatialAudio';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
const iconMap = {
    GamepadIcon: GamepadIcon,
    TerminalIcon: TerminalIcon,
    FeedbackIcon: FeedbackIcon,
    GroupIcon: GroupIcon,
    CampaignIcon: CampaignIcon,
    ThumbDownAltIcon: ThumbDownAltIcon,
    SpeakerNotesOffIcon: SpeakerNotesOffIcon,
    FormatStrikethroughIcon: FormatStrikethroughIcon,
    FileCopyIcon: FileCopyIcon,
    SpatialAudioIcon: SpatialAudioIcon,
    DriveFileRenameOutlineIcon: DriveFileRenameOutlineIcon
};
function BanReasonIcon({ icon }) {
    const theme = useTheme();
    const IconComponent = iconMap[icon];
    return (React.createElement(IconComponent, { sx: { fontSize: 100, color: theme.palette.secondary.main } }));
}
export default function Ban({ model: ban }) {
    const theme = useTheme();
    return (React.createElement(Button, { id: "ban" }, ban ?
        React.createElement(Stack, { direction: 'row', alignItems: 'center', flex: '1', gap: '1rem', width: "-webkit-fill-available", m: '0.5rem' },
            React.createElement(BanReasonIcon, { icon: ban?.reason?.icon || 'FeedbackIcon' }),
            React.createElement(Stack, { gap: '0.5rem', style: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } },
                React.createElement(Typography, { id: "ban-h1", noWrap: true }, ban?.reason?.type),
                React.createElement(Stack, { direction: "row", alignItems: "center", gap: "0.5rem" },
                    React.createElement(LockIcon, { sx: { fontSize: 39, color: theme.palette.secondary.main } }),
                    ban?.features?.map(feature => React.createElement(Badge, null, feature)))),
            React.createElement("span", { style: { flex: 1 } }),
            React.createElement(Stack, { gap: '0.5rem' },
                React.createElement(Stack, { direction: "row", alignItems: "center", gap: '1rem' },
                    React.createElement(AccessTimeIcon, { sx: { fontSize: 39, color: theme.palette.secondary.main } }),
                    React.createElement("h2", { style: { textAlign: 'right' } },
                        Convert.numberToHumanDate(ban?.createdAt),
                        "    \u2192    ",
                        Convert.numberToHumanDate(ban?.expireAt)),
                    React.createElement("div", { style: { minWidth: "8rem" } },
                        React.createElement(Badge, null, Convert.numberToHumanDuration(ban?.duration) || 'no duration'))),
                React.createElement(Stack, { direction: "row", alignItems: "center", gap: '0.5rem' },
                    React.createElement(GavelIcon, { sx: { fontSize: 25, color: theme.palette.secondary.main } }),
                    React.createElement("p", { style: { textAlign: 'right' } },
                        "by : ",
                        ban.bannedBy?.pseudo),
                    React.createElement("span", { style: { flex: 1 } }),
                    ban.liftedBy?.id !== '0' &&
                        React.createElement("p", { style: { textAlign: 'right' } },
                            "lifted by : ",
                            ban.liftedBy?.pseudo),
                    React.createElement("div", { style: { minWidth: "8rem" } },
                        React.createElement(Badge, { sx: { background: ban?.status?.color } }, ban?.status.label)))))
        :
            React.createElement("div", { id: "new-ban-button" },
                React.createElement(Typography, { fontSize: "1.5rem" }, "NEW BAN"))));
}
