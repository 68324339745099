// From Material-ui nextjs sample https://github.com/mui-org/material-ui/tree/master/examples/nextjs
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';
import createEmotionCache from '../src/styles/createEmotionCache';
import { useMode } from '../src/styles/theme';
import { RouterProvider, createBrowserRouter, createRoutesFromElements, redirect, Route, } from 'react-router-dom';
import './index.css';
import ListPlayers, { loader as dashboardLoader, action as dashboardAction, } from './routes/players/list-players';
import ErrorPage from './routes/error-page';
import Player, { loader as playerLoader, action as playerAction, } from './routes/players/player';
import Login, { loader as loginLoader } from './routes/auth/login';
import Root from './routes/root';
import { authProvider } from './authentication/auth';
import SSOMicrosoft, { action as ssoMicrosoftAction, } from './routes/auth/sso-microsoft';
import LoginFormResult, { action as loginFormAction, } from './routes/auth/login-form';
import ListBans, { action as dashboardBanAction, } from './routes/bans/list-bans';
import EditBan, { action as editBanAction } from './routes/bans/edit';
import { action as deleteBanAction } from './routes/bans/delete';
import { action as deletePlayerAction } from './routes/players/delete';
import { loader as gameLoader } from './routes/dasboard/dashboard';
import Dashboard from './routes/dasboard/dashboard';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import GameSelect from './routes/game/game-select';
import { gamedatas } from './game/gamedata';
// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();
const router = createBrowserRouter(createRoutesFromElements(React.createElement(Route, { path: "/", id: "root", element: React.createElement(Root, null), loader: async ({ request }) => {
        const isAuthenticated = await authProvider.isAuthenticated();
        if (!isAuthenticated && !request.url.includes('login')) {
            return authProvider.getRedirectResponse(request);
        }
        return { user: authProvider.username, isAuthenticated };
    }, errorElement: React.createElement(ErrorPage, null) },
    React.createElement(Route, { path: "/", loader: async () => {
            return redirect('/game');
        } }),
    React.createElement(Route, { path: "*", element: React.createElement(ErrorPage, { message: "Can't find path" }) }),
    React.createElement(Route, { path: "login", element: React.createElement(Login, null), loader: loginLoader },
        React.createElement(Route, { path: "login-form", element: React.createElement(LoginFormResult, null), action: loginFormAction }),
        React.createElement(Route, { path: "sso-microsoft", element: React.createElement(SSOMicrosoft, null), action: ssoMicrosoftAction })),
    React.createElement(Route, { path: "logout", action: async () => {
            await authProvider.signout();
            return redirect('/login');
        } }),
    React.createElement(Route, { path: "game", element: React.createElement(GameSelect, { items: gamedatas }) }),
    React.createElement(Route, { path: "game/:gameId", loader: gameLoader, element: React.createElement(Dashboard, { menu: true, items: [
                {
                    link: 'players',
                    label: 'Players',
                    icon: React.createElement(PeopleOutlinedIcon, null),
                    items: [],
                },
                {
                    link: 'store',
                    label: 'Store',
                    icon: React.createElement(LocalMallIcon, null),
                    items: [{
                            link: 'items',
                            label: 'Items',
                            icon: null,
                            items: [],
                        },
                        {
                            link: 'layout',
                            label: 'Store',
                            icon: null,
                            items: [],
                        },
                    ],
                    disabled: true,
                },
            ] }) },
        React.createElement(Route, { path: "*", element: React.createElement(ErrorPage, { message: "Can't find path" }) }),
        React.createElement(Route, { errorElement: React.createElement(ErrorPage, null) },
            React.createElement(Route, { path: "players", element: React.createElement(ListPlayers, null), loader: dashboardLoader, action: dashboardAction, errorElement: React.createElement(ErrorPage, null) },
                React.createElement(Route, { path: ":playerId", id: "player", element: React.createElement(Player, null), loader: playerLoader, action: playerAction, errorElement: React.createElement(ErrorPage, null) },
                    React.createElement(Route, { errorElement: React.createElement(ErrorPage, null) },
                        React.createElement(Route, { path: "", loader: () => { return { game: undefined }; }, element: React.createElement(Dashboard, { menu: false, items: [
                                    {
                                        link: 'bans',
                                        label: 'Bans',
                                        icon: React.createElement(BlockOutlinedIcon, null),
                                        items: [],
                                    },
                                    {
                                        link: 'inventory',
                                        label: 'Inventory',
                                        icon: React.createElement(InventoryIcon, null),
                                        items: [],
                                        disabled: true,
                                    },
                                ] }), errorElement: React.createElement(ErrorPage, null) },
                            React.createElement(Route, { path: "*", element: React.createElement(ErrorPage, { message: "Can't find path" }) }),
                            React.createElement(Route, { path: "bans", element: React.createElement(ListBans, null), action: dashboardBanAction, errorElement: React.createElement(ErrorPage, null) }),
                            React.createElement(Route, { path: "bans/create", element: React.createElement(EditBan, null), action: editBanAction }),
                            React.createElement(Route, { path: "bans/:banId/edit", element: React.createElement(EditBan, null), action: editBanAction }),
                            React.createElement(Route, { path: "bans/:banId/edit/delete", action: deleteBanAction })),
                        React.createElement(Route, { path: "delete", action: deletePlayerAction }))),
                React.createElement(Route, { path: "*", element: React.createElement(ErrorPage, { message: "Can't find path" }) })))))));
export default function MyApp({ emotionCache = clientSideEmotionCache }) {
    const [theme] = useMode();
    return (React.createElement(CacheProvider, { value: emotionCache },
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement("div", { className: "watermark-container" },
                React.createElement("div", { className: "watermark" })),
            React.createElement(CssBaseline, null),
            React.createElement(RouterProvider, { router: router }))));
}
