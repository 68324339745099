import React from 'react';
import { redirect, Outlet, useNavigate, useOutlet, } from 'react-router-dom';
import { createPlayer } from '../../models/players';
import { authProvider } from '../../authentication/auth';
import DataTable from '../../components/datatable';
import { Box } from '@mui/material';
import CustomDrawer from '../../components/customDrawer';
export async function action({ request }) {
    const createPlayerResponse = await createPlayer();
    if (createPlayerResponse.unauthorized) {
        return authProvider.getRedirectResponse(request);
    }
    if (createPlayerResponse.result) {
        return redirect(`/players/${createPlayerResponse.result.id}/edit`);
    }
}
export async function loader({ request, }) {
    return new Response(request.url);
}
export default function ListPlayers() {
    const navigate = useNavigate();
    const outlet = useOutlet();
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { id: "sidebar", className: "sidebar-window" },
            React.createElement("h1", null, "Players"),
            React.createElement("div", { style: { display: 'inline' } },
                React.createElement(DataTable, { onRowClick: (row) => {
                        navigate(`${row.id}/bans`);
                    } }))),
        React.createElement(CustomDrawer, { open: outlet != null, variant: "persistent", anchor: "right" },
            React.createElement(Outlet, null))));
}
