import React from 'react';
import { redirect, useActionData } from 'react-router-dom';
import { authProvider } from '../../authentication/auth';
class AuthenticationService {
    constructor() {
        this.initialized = false;
        this.login = async () => {
            if (!this.initialized) {
                this.initialized = true;
            }
            alert('Authentication method not implemented');
            return false;
        };
        console.log('AuthenticationService');
    }
}
export async function action({ request }) {
    try {
        const authService = new AuthenticationService();
        const loginResult = await authService.login();
        const formData = await request.formData();
        if (loginResult) {
            await authProvider.signin('username', 'idToken');
        }
        const redirectTo = formData.get('redirectTo');
        return redirect(redirectTo || '/');
    }
    catch (error) {
        const err = error;
        return {
            error: err.message,
        };
    }
}
export default function LoginFormResult() {
    const errors = useActionData();
    return React.createElement("div", null, errors ? JSON.stringify(errors) : 'Connecting ...');
}
