import { getReasonFromCode } from './reasons';
// import { sendRequest } from './network';
import { Convert } from '../utils/tools';
import { sendRequest } from './network';
export const BanStatus = {
    CREATION: { label: 'creation', color: 'blue' },
    RUNNING: { label: 'running', color: 'yellow' },
    LIFTED: { label: 'lifted', color: 'green' },
    LAPSED: { label: 'lapsed', color: 'grey' },
    DELETED: { label: 'deleted', color: 'red' },
    UNKNOWN: { label: 'unknown', color: 'pink' },
};
const banStatusLabelToEnumMap = {
    creation: BanStatus.CREATION,
    running: BanStatus.RUNNING,
    lifted: BanStatus.LIFTED,
    lapsed: BanStatus.LAPSED,
    deleted: BanStatus.DELETED,
    unknown: BanStatus.UNKNOWN,
};
export function banDtoToModel(banDto) {
    const createdAt = Convert.dateStringToNumber(banDto.created_at);
    const duration = Convert.durationToNumber(banDto.duration);
    const expireAt = createdAt + duration;
    return {
        id: `${banDto.ban_id}`,
        features: banDto.feature_codes,
        reason: getReasonFromCode(banDto.reason_code),
        bannedBy: userDtoToModel(banDto.banned_by),
        createdAt: Convert.dateStringToNumber(banDto.created_at),
        expireAt: expireAt,
        duration: Convert.durationToNumber(banDto.duration),
        description: banDto.description,
        status: banStatusLabelToEnumMap[banDto.status] ?? BanStatus.UNKNOWN,
        liftedReason: banDto.lifted_reason,
        liftedBy: userDtoToModel(banDto.lifted_by),
    };
}
export function userDtoToModel(userDto) {
    if (!userDto) {
        return {};
    }
    const createdAt = Convert.dateStringToNumber(userDto.created_at);
    return {
        id: `${userDto.user_id}`,
        pseudo: userDto.email.split('@')[0],
        createdAt: createdAt,
        firstName: userDto.firstname,
        lastName: userDto.lastname,
        email: userDto.email,
    };
}
let loadedBans = undefined;
export async function createBan(playerId, updates) {
    const ban = {
        playerId: playerId,
        createdAt: Date.now(),
        status: BanStatus.CREATION,
        features: updates.features,
        duration: Convert.hoursToMilliseconds(updates.duration),
    };
    const params = {
        player_id: +playerId,
        reason_code: updates.reason?.id,
        description: updates.description,
        duration: Convert.numberToDuration(Convert.hoursToMilliseconds(updates.duration)),
        feature_codes: updates.features,
    };
    //const response = await fakeCreateBan(params);
    const response = await sendRequest('ban', 'POST', params);
    return {
        result: ban,
        unauthorized: response.unauthorized,
    };
}
export async function updateBan(id, updates) {
    //const response = await fakeUpdateBan(id, {
    const response = await sendRequest(`ban/${id}`, 'PUT', {
        description: updates.description,
    });
    return {
        result: true,
        unauthorized: response.unauthorized,
    };
}
export async function liftBan(id, reason) {
    // const response = await fakeUpdateBan(id, {
    const response = await sendRequest(`ban/${id}/status/lift`, 'PUT', {
        reason,
    });
    if (response.result) {
        return {
            result: banDtoToModel(response.result),
            unauthorized: response.unauthorized,
        };
    }
    return {
        result: response.result,
        unauthorized: response.unauthorized,
    };
}
export async function deleteBan(id) {
    console.warn(`deleteBan ${id} : not implemented`);
    return {
        result: false,
        unauthorized: false,
    };
}
