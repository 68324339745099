import tdu_miniature from "../images/TDUSC.png";
import nacon from "../images/NACON.png";
export const gamedatas = [{
        id: 0,
        code: "TDUSC",
        name: "Test Drive Unlimited Solar Crown",
        description: "TDUSC is a lifestyle and social experience that redefines what an open-world racing game can be. Explore a fully recreated Hong Kong Island.",
        image: tdu_miniature
    },
    {
        id: 1,
        code: "EX1",
        name: "Exemple game 1",
        description: "This game is an example for visualisation of game miniatures. It has no rattached api key and data.",
        image: nacon
    },
    {
        id: 2,
        code: "EX2",
        name: "Exemple game 2",
        description: "This game is an example for visualisation of game miniatures. It has no rattached api key and data.",
        image: nacon
    },];
