import React from 'react';
import { Outlet, useLoaderData } from 'react-router-dom';
import { MyProSidebar } from '../../components/sidebar/MyProSidebar';
import { AuthStatus } from '../../authentication/auth-status';
import { gamedatas } from '../../game/gamedata';
export async function loader({ params, request }) {
    const game = gamedatas.find(game => game.id === +params.gameId);
    return { game };
}
export default function Dashboard(props) {
    const { game } = useLoaderData();
    return (React.createElement(MyProSidebar, { items: props.items, selectGame: props.menu, selectedGame: game },
        props.menu && React.createElement(AuthStatus, null),
        React.createElement("div", { style: { display: 'flex', flex: 1, flexDirection: 'row' } },
            React.createElement(Outlet, null))));
}
