import React from 'react';
import { Form, Outlet, redirect, useLocation, useOutlet } from 'react-router-dom';
import { authProvider } from '../../authentication/auth';
import { Box, Button, Divider, Stack, Tab, Tabs, Typography } from '@mui/material';
import logo from '../../images/LiveLogo.png';
export async function loader() {
    if (await authProvider.isAuthenticated()) {
        return redirect('/game');
    }
    return null;
}
function LoginHeader() {
    return (React.createElement(Stack, { textAlign: "center", style: {
            position: 'absolute',
            width: '400px',
            left: '50%',
            marginLeft: '-200px',
            top: '40px',
            display: 'flex',
            flexDirection: 'column',
            zIndex: '-1'
        } },
        React.createElement("img", { alt: "Asset Sharing Tool logo", style: {
                width: '220px',
                marginTop: '15px',
                marginBottom: '10px',
                marginLeft: 'auto',
                marginRight: 'auto'
            }, src: logo }),
        React.createElement(Typography, { color: "white" }, "Staging")));
}
export default function Login() {
    // const [username, setUsername] = useState("");
    // const [password, setPassword] = useState("");
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const from = params.get('from') || '/';
    const outlet = useOutlet();
    // const navigation = useNavigation();
    // const isLoggingIn = navigation.formData?.get("username") != null;
    // const actionData = useActionData() as { error: string } | undefined;
    return (React.createElement(React.Fragment, null,
        React.createElement(LoginHeader, null),
        React.createElement(Box, { width: '400px', height: '400px', position: 'absolute', left: '50vw', top: '50vh', display: 'flex', flexDirection: 'column', style: {
                transform: 'translate(-50%, -50%)'
            } },
            React.createElement(Tabs, { value: 'login' },
                React.createElement(Tab, { label: "Login", value: "login" })),
            React.createElement(Divider, null),
            React.createElement(Form, { method: "post", action: "sso-microsoft", replace: true, id: "login-form" },
                React.createElement("input", { type: "hidden", name: "redirectTo", value: from }),
                React.createElement(Button, { type: "submit", fullWidth: true, id: "login-button" }, "SSO Microsoft")),
            React.createElement(Box, { id: 'login-error-box', height: outlet ? '4rem' : 0 },
                React.createElement(Outlet, null))))
    // <div style={{ margin: 'auto', marginTop: '10vh', minWidth: '10vw' }}>
    //   <h1>Login</h1>
    //   {/* <Form method="post" action="login-form" replace>
    //     <div id="login-form">
    //       <input type="hidden" name="redirectTo" value={from} />
    //       <label>
    //         Username:
    //         <input type="text" name="username" value={username} onChange={(e) => setUsername(e.target.value)} />
    //       </label>
    //       <label>
    //         Password:
    //         <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
    //       </label>
    //       <Button type="submit">Login</Button>
    //     </div>
    //   </Form>
    //   <h2>-- OR --</h2> */}
    //   <Form method="post" action="sso-microsoft" replace>
    //     <input type="hidden" name="redirectTo" value={from} />
    //     <div id="login-form">
    //       <Button type="submit">SSO Microsoft</Button>
    //     </div>
    //   </Form>
    //   <Outlet />
    // </div>
    );
}
