import { Divider, Drawer } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useCallback } from "react";
export const defaultDrawerWidth = window.innerWidth * 0.75;
const minDrawerWidth = 10;
const maxDrawerWidth = window.innerWidth;
const useStyles = makeStyles(theme => ({
    drawer: {
        flexShrink: 0
    },
    // toolbar: theme.mixins.toolbar,
    dragger: {
        width: "5px",
        cursor: "ew-resize",
        padding: "4px 0 0",
        borderTop: "1px solid #ddd",
        position: "absolute",
        top: -1,
        left: 0,
        bottom: 0,
        zIndex: 100,
        backgroundColor: "#393939"
    }
}));
export default function CustomDrawer({ children, ...props }) {
    const classes = useStyles();
    const [drawerWidth, setDrawerWidth] = React.useState(defaultDrawerWidth);
    const handleMouseDown = (e) => {
        e.preventDefault();
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };
    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };
    const handleTouchStart = (e) => {
        document.addEventListener("touchend", handleTouchEnd, true);
        document.addEventListener("touchmove", (e) => handleMouseMove(e.touches[0]), true);
    };
    const handleTouchEnd = () => {
        document.removeEventListener("touchend", handleTouchEnd, true);
        document.removeEventListener("touchmove", (e) => handleMouseMove(e.touches[0]), true);
    };
    const handleMouseMove = useCallback((e) => {
        const newWidth = document.body.offsetWidth - e.clientX;
        if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
            setDrawerWidth(newWidth);
        }
    }, []);
    return (React.createElement(Drawer, { className: classes.drawer, variant: "permanent", PaperProps: { style: { width: drawerWidth } }, ...props },
        React.createElement("div", { onMouseDown: e => handleMouseDown(e), onTouchStart: e => handleTouchStart(e), className: classes.dragger }),
        React.createElement(Divider, null),
        children));
}
