import React from 'react';
import { redirect, useActionData, useNavigate } from 'react-router-dom';
import { authProvider } from '../../authentication/auth';
import * as Msal from '@azure/msal-browser';
import { msalConfig } from '../../authentication/authConfig';
class AuthenticationService {
    constructor() {
        this.initialized = false;
        this.login = async () => {
            if (!this.initialized) {
                await this.app.initialize();
                this.initialized = true;
            }
            return this.app.loginPopup({
                scopes: [`api://${msalConfig.auth.clientId}/Default.All`],
            });
        };
        this.app = new Msal.PublicClientApplication(msalConfig);
    }
}
export async function action({ request }) {
    const authService = new AuthenticationService();
    try {
        const loginResult = await authService.login();
        const formData = await request.formData();
        await authProvider.signin(loginResult.account.username, loginResult.accessToken);
        const redirectTo = formData.get('redirectTo');
        return redirect(redirectTo || '/');
    }
    catch (error) {
        const err = error;
        return {
            error: err.message,
        };
    }
}
export default function SSOMicrosoft() {
    const errors = useActionData();
    const navigate = useNavigate();
    React.useEffect(() => {
        if (!errors) {
            navigate('/login', { replace: true });
        }
    }, [errors]);
    return React.createElement("div", null, errors ? JSON.stringify(errors) : 'Connecting ...');
}
