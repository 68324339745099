import React from 'react';
import { redirect, useRouteLoaderData, NavLink, } from 'react-router-dom';
import Ban from './ban';
export async function action() {
    return redirect('create');
}
export default function ListBans() {
    const { player } = useRouteLoaderData('player');
    // const { player } = useRouteLoaderData('player') as { player: PlayerModel };
    return (React.createElement("div", { id: "sidebar", className: "sidebar-window" },
        React.createElement("h1", null, "Bans"),
        React.createElement("nav", null,
            React.createElement("ul", null,
                React.createElement("li", null,
                    React.createElement(NavLink, { to: `create`, className: ({ isActive, isPending }) => isActive ? 'active' : isPending ? 'pending' : '' },
                        React.createElement(Ban, { model: undefined }))),
                player.bans.length ? (player.bans.map((ban) => (React.createElement("li", { key: ban.id },
                    React.createElement(NavLink, { to: `${ban.id}/edit`, className: ({ isActive, isPending }) => isActive ? 'active' : isPending ? 'pending' : '' },
                        React.createElement(Ban, { model: ban })))))) : (React.createElement("p", null,
                    React.createElement("i", null, "No bans")))))));
}
