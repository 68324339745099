import React from 'react';
import { Form, redirect, useNavigate, useRouteLoaderData, useParams, } from 'react-router-dom';
import { BanStatus, createBan, liftBan, updateBan, } from '../../models/bans';
import { banReasons } from '../../models/reasons';
import { banFeatures } from '../../models/features';
import { Convert } from '../../utils/tools';
import { authProvider } from '../../authentication/auth';
import { Badge, Button, FormControl, InputLabel, ListSubheader, MenuItem, OutlinedInput, Select, Stack, TextField } from '@mui/material';
import { RouterError } from '../error-page';
export async function action({ request, params }) {
    const formData = await request.formData();
    const banId = params.banId;
    const updates = Object.fromEntries(formData);
    const partialBan = updates;
    if (partialBan.duration && !+partialBan.duration) {
        alert('Please select a non zero duration');
        return null;
    }
    const intent = formData.get('intent');
    const features = formData.get('fixed-select-features');
    let response;
    //  revoke ban intent
    if (intent === 'revoke') {
        response = await liftBan(banId, partialBan.liftedReason);
    }
    else {
        if (updates.reason !== undefined) {
            partialBan.reason = banReasons.find((reason) => reason.id === updates.reason);
        }
        partialBan.features = JSON.parse(features);
        if (!banId) {
            response = await createBan(params.playerId, partialBan);
        }
        else {
            response = await updateBan(banId, partialBan);
        }
    }
    if (response.unauthorized) {
        return authProvider.getRedirectResponse(request);
    }
    return redirect(`/game/${params.gameId}/players/${params.playerId}/bans`);
}
function BanTimeSelector(props) {
    const { selectedDate, onChangeDate } = props;
    const startTime = (new Date()).setHours(8, 0, 0, 0);
    const [selectedMonth, setSelectedMonth] = React.useState(0);
    const [selectedWeek, setSelectedWeek] = React.useState(0);
    const [selectedDay, setSelectedDay] = React.useState(0);
    const [startDate] = React.useState(startTime);
    const [outputDate, setOutputDate] = React.useState(startTime);
    const handleMonthChange = (event) => {
        setSelectedMonth(+event.target.value);
        updateOutputDate({ month: +event.target.value });
    };
    const handleWeekChange = (event) => {
        setSelectedWeek(+event.target.value);
        updateOutputDate({ week: +event.target.value });
    };
    const handleDayChange = (event) => {
        setSelectedDay(+event.target.value);
        updateOutputDate({ day: +event.target.value });
    };
    const updateOutputDate = (options) => {
        const startFullDate = new Date(startDate);
        const month = options.month !== undefined ? options.month : selectedMonth;
        const week = options.week !== undefined ? options.week : selectedWeek;
        const day = options.day !== undefined ? options.day : selectedDay;
        const outputDate = new Date(startFullDate.getFullYear(), startFullDate.getMonth() + month, startFullDate.getDate() + 7 * week + day, 8).getTime();
        setOutputDate(outputDate);
    };
    return (React.createElement(Stack, { direction: "row", spacing: 2, alignItems: "center", gap: "1vw", width: "100%" },
        React.createElement("input", { value: (outputDate - startDate) / (60 * 1000), type: "number", hidden: true, name: "duration" }),
        React.createElement(FormControl, null,
            React.createElement(InputLabel, { id: "select-month-label" }, "Month(s)"),
            React.createElement(Select, { labelId: "select-month-label", id: "select-month", name: "month", defaultValue: 0, input: React.createElement(OutlinedInput, { label: "Month(s)" }), 
                // value={selectedMonth}
                onChange: handleMonthChange }, Array.from({ length: 13 }, (_, index) => (React.createElement(MenuItem, { key: index, value: index }, index))))),
        React.createElement(FormControl, null,
            React.createElement(InputLabel, { id: "select-week-label" }, "Week(s)"),
            React.createElement(Select, { labelId: "select-week-label", id: "select-week", name: "week", defaultValue: 0, input: React.createElement(OutlinedInput, { label: "Week(s)" }), MenuProps: {
                    style: {
                        maxHeight: 400,
                    },
                }, 
                // value={selectedWeek}
                onChange: handleWeekChange }, Array.from({ length: 52 }, (_, index) => (React.createElement(MenuItem, { key: index, value: index }, index))))),
        React.createElement(FormControl, null,
            React.createElement(InputLabel, { id: "select-day-label" }, "Day(s)"),
            React.createElement(Select, { labelId: "select-day-label", id: "select-day", name: "day", defaultValue: 0, input: React.createElement(OutlinedInput, { label: "Day(s)" }), MenuProps: {
                    style: {
                        maxHeight: 400,
                    },
                }, 
                // value={selectedDay}
                onChange: handleDayChange }, Array.from({ length: 32 }, (_, index) => (React.createElement(MenuItem, { key: index, value: index }, index))))),
        React.createElement(Stack, { direction: "row", gap: "3vw", style: { flex: 1 }, alignItems: "center" },
            React.createElement("h3", { style: { textAlign: 'right', flex: 1, opacity: 0 } }, "duration badge (preview)"),
            React.createElement(Badge, { style: { fontWeight: "bold" } }, Convert.numberToHumanDuration(outputDate - startDate)),
            React.createElement("h3", { style: { textAlign: 'right', flex: 1 } },
                Convert.numberToHumanDate(startDate),
                "    \u2192    ",
                Convert.numberToHumanDate(outputDate)))));
}
export default function EditBan() {
    const [editDescription, setEditDescription] = React.useState(false);
    const [selectedValues, setSelectedValues] = React.useState([]);
    const navigate = useNavigate();
    const { player } = useRouteLoaderData('player');
    const { playerId, banId } = useParams();
    const ban = banId ?
        player.bans.find(b => b.id === banId)
        : {
            playerId: playerId,
            status: BanStatus.CREATION,
            features: [],
            duration: 0,
        };
    if (!ban) {
        throw new RouterError(`Ban ${banId} for player ${playerId} could not be found`, 404);
    }
    const groupedReasons = banReasons.reduce((acc, item) => {
        const category = item.category || '';
        // Check if the accumulator has a key for the current category
        if (!acc[category]) {
            acc[category] = [];
        }
        // Push the current item into the appropriate category array
        acc[category].push(item);
        return acc;
    }, {});
    const reasonCategories = Object.keys(groupedReasons);
    const groupedFeatures = banFeatures.reduce((acc, item) => {
        const category = item.category || '';
        // Check if the accumulator has a key for the current category
        if (!acc[category]) {
            acc[category] = [];
        }
        // Push the current item into the appropriate category array
        acc[category].push(item);
        return acc;
    }, {});
    const featureCategories = Object.keys(groupedFeatures);
    return (React.createElement(Form, { method: "post", id: "ban-form" },
        React.createElement("label", null,
            React.createElement("span", { style: { width: '8rem' } }, "Reason"),
            React.createElement(FormControl, { sx: { width: "100%" } },
                React.createElement(InputLabel, { id: "select-reason-label" }, "Reason"),
                React.createElement(Select, { labelId: "select-reason-label", id: "select-reason", disabled: ban?.status !== BanStatus.CREATION, input: React.createElement(OutlinedInput, { label: "Reason" }), name: "reason", defaultValue: ban?.reason?.id, required: true },
                    React.createElement(MenuItem, { value: "" },
                        React.createElement("em", null, "None")),
                    reasonCategories.map((category, index) => ([
                        React.createElement(ListSubheader, { key: `category-${index}` }, category),
                        groupedReasons[category].map((reason, indexC) => (React.createElement(MenuItem, { key: `item-${index}-${indexC}`, value: reason.id }, reason.type)))
                    ]))))),
        React.createElement("label", null,
            React.createElement("span", { style: { width: '8rem' } }, "Features"),
            React.createElement(FormControl, { sx: { width: "100%" } },
                React.createElement(InputLabel, { id: "select-feature-label" }, "Features"),
                React.createElement("input", { name: 'fixed-select-features', value: JSON.stringify(selectedValues), hidden: true, readOnly: true }),
                React.createElement(Select, { labelId: "select-feature-label", id: "select-feature", disabled: ban?.status !== BanStatus.CREATION, input: React.createElement(OutlinedInput, { label: "Features" }), name: "broken-select-features", defaultValue: ban?.features, multiple: true, placeholder: 'Select Features to Ban', onChange: (e) => { setSelectedValues(e.target.value); }, required: true }, featureCategories.map((category, index) => ([
                    React.createElement(ListSubheader, { key: `category-${index}` }, category),
                    groupedFeatures[category].map((feature, indexC) => (React.createElement(MenuItem, { key: `item-${index}-${indexC}`, value: feature.id }, feature.type)))
                ]))))),
        React.createElement("label", null,
            React.createElement("span", { style: { width: '8rem' } }, "Duration"),
            ban?.status === BanStatus.CREATION ? (React.createElement(BanTimeSelector, null)) : ban?.status === BanStatus.LAPSED ? (React.createElement("p", null, `${Convert.numberToHumanDuration(ban.duration)} (EXPIRED)`)) : ban?.status === BanStatus.LIFTED ? (React.createElement("p", null, `${Convert.numberToHumanDuration(ban.duration)} (LIFTED)`)) : (React.createElement(React.Fragment, null,
                React.createElement("input", { disabled: true, type: "range", min: ban?.createdAt, max: ban?.expireAt, defaultValue: Date.now(), style: { boxShadow: 'none' } }),
                React.createElement("p", { style: { margin: '0 0 0 3rem' } }, `${Convert.numberToHumanDuration(ban?.duration)}`)))),
        React.createElement("label", null,
            React.createElement("span", { style: { width: '8rem' } },
                "Description",
                !(ban?.status !== BanStatus.RUNNING || editDescription) &&
                    React.createElement(Button, { type: "button", onClick: () => {
                            setEditDescription(true);
                        } }, "Edit")),
            React.createElement(TextField, { disabled: !(ban?.status === BanStatus.CREATION || editDescription), name: "description", defaultValue: ban?.description, rows: 6, fullWidth: true, multiline: true })),
        ((ban?.status === BanStatus.RUNNING && !editDescription) ||
            ban?.status === BanStatus.LIFTED) && (React.createElement("label", null,
            React.createElement("span", { style: { width: '8rem' } }, "Lift Reason"),
            React.createElement(TextField, { disabled: ban?.status === BanStatus.LIFTED, name: "liftedReason", defaultValue: ban?.liftedReason, rows: 6, required: true, fullWidth: true, multiline: true }))),
        React.createElement("p", null,
            ((ban?.status === BanStatus.RUNNING && editDescription) ||
                ban?.status === BanStatus.CREATION) &&
                React.createElement(Button, { type: "submit", name: "intent", value: "save" }, "Save"),
            (ban?.status == BanStatus.RUNNING && !editDescription) &&
                React.createElement(Button, { type: "submit", name: "intent", value: "revoke" }, "Lift Ban"),
            React.createElement(Button, { type: "button", onClick: () => {
                    navigate(-1);
                } }, "Cancel"))));
}
